import React from 'react';

const VisaStatus = ({
  selectedCategory,
  setSelectedCategory,
  selectedVisaPath,
  setSelectedVisaPath,
  calculateTotalDays,
  isOverLimit,
  getVisaPath,
  visaCategories,
  travelRecords
}) => {
  if (!visaCategories) {
    return <div>Loading visa categories...</div>;
  }

  const selectedPath = selectedVisaPath ? getVisaPath(selectedVisaPath) : null;
  const totalDays = calculateTotalDays();
  const overLimit = selectedPath ? isOverLimit(totalDays, selectedVisaPath) : false;

  return (
    <div className="visa-section" style={{
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      padding: '20px',
      margin: '0',
      flex: 1
    }}>
      <h2 style={{ 
        marginTop: '0',
        marginBottom: '20px',
        fontSize: '1.5rem'
      }}>Visa Status Information</h2>
      
      <div style={{ marginBottom: '15px' }}>
        <label style={{ 
          display: 'block',
          marginBottom: '8px'
        }}>Select Visa Category:</label>
        <select
          value={selectedCategory}
          onChange={(e) => {
            setSelectedCategory(e.target.value);
            setSelectedVisaPath('');
          }}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc'
          }}
        >
          <option value="">Select a visa category</option>
          {Object.keys(visaCategories).map(categoryKey => (
            <option key={categoryKey} value={categoryKey}>
              {visaCategories[categoryKey].title}
            </option>
          ))}
        </select>
      </div>

      {selectedCategory && visaCategories[selectedCategory] && (
        <div style={{ marginBottom: '15px' }}>
          <label style={{ 
            display: 'block',
            marginBottom: '8px'
          }}>Select Immigration Path:</label>
          <select
            value={selectedVisaPath}
            onChange={(e) => setSelectedVisaPath(e.target.value)}
            style={{
              width: '100%',
              padding: '8px',
              borderRadius: '4px',
              border: '1px solid #ccc'
            }}
          >
            <option value="">Select your immigration path</option>
            {visaCategories[selectedCategory].paths.map(path => (
              <option key={path.id} value={path.id}>
                {path.label}
              </option>
            ))}
          </select>
        </div>
      )}

      {selectedPath && (
        <div style={{ marginTop: '20px' }}>
          <h3 style={{ fontSize: '1.2rem', marginBottom: '15px' }}>
            Absence Limits for Your Status
          </h3>
          
          <div style={{ marginBottom: '10px' }}>
            <strong>Maximum Absence:</strong> {selectedPath.limit}
          </div>
          
          <div style={{ marginBottom: '10px' }}>
            <strong>Period Type:</strong> {selectedPath.period}
          </div>
          
          <div style={{ marginBottom: '15px' }}>
            <strong>Your Current Total:</strong>{' '}
            <span style={{ 
              color: overLimit ? '#dc3545' : 'inherit',
              fontWeight: overLimit ? 'bold' : 'normal'
            }}>
              {totalDays} days {overLimit ? '(Over limit!)' : ''}
            </span>
          </div>

          <div style={{ marginTop: '20px' }}>
            <h4 style={{ fontSize: '1.1rem', marginBottom: '10px' }}>
              Important Conditions:
            </h4>
            <ul style={{ 
              paddingLeft: '20px',
              margin: '0'
            }}>
              {selectedPath.conditions.map((condition, index) => (
                <li key={index} style={{ marginBottom: '5px' }}>
                  {condition}
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default VisaStatus;