import { 
    collection,
    addDoc,
    updateDoc,
    deleteDoc,
    doc,
    query,
    where,
    getDocs,
    serverTimestamp
} from 'firebase/firestore';
import { db } from '../firebase';

export const travelRecordsService = {
    // Get all travel records for a user
    async getUserRecords(userId) {
        const q = query(
            collection(db, 'travelRecords'),
            where('userId', '==', userId)
        );
        
        const querySnapshot = await getDocs(q);
        return querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
    },

    // Add a new travel record
    async addRecord(userId, recordData) {
        try {
            const docRef = await addDoc(collection(db, 'travelRecords'), {
                ...recordData,
                userId,
                createdAt: serverTimestamp()
            });
            
            // Return the complete record with ID
            return {
                id: docRef.id,
                ...recordData,
                userId,
                createdAt: new Date()
            };
        } catch (error) {
            console.error('Error adding record:', error);
            throw new Error('Failed to add record');
        }
    },

    // Update an existing record
    async updateRecord(recordId, recordData) {
        try {
            const docRef = doc(db, 'travelRecords', recordId);
            await updateDoc(docRef, {
                ...recordData,
                updatedAt: serverTimestamp()
            });
            
            // Return the updated record
            return {
                id: recordId,
                ...recordData,
                updatedAt: new Date()
            };
        } catch (error) {
            console.error('Error updating record:', error);
            throw new Error('Failed to update record');
        }
    },

    // Delete a record
    async deleteRecord(recordId) {
        try {
            const docRef = doc(db, 'travelRecords', recordId);
            await deleteDoc(docRef);
            return recordId;
        } catch (error) {
            console.error('Error deleting record:', error);
            throw new Error('Failed to delete record');
        }
    }
};