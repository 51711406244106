import React, { useState, useEffect } from 'react';
import { Cookie } from 'lucide-react';
import { Link } from 'react-router-dom';

const CookieConsent = () => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setShow(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('cookieConsent', 'accepted');
    setShow(false);
  };

  const handleDecline = () => {
    localStorage.setItem('cookieConsent', 'declined');
    setShow(false);
  };

  if (!show) return null;

  return (
    <>
      <div className="cookie-overlay" />
      <div className="cookie-consent">
        <h2>
          <Cookie size={24} />
          Cookies Consent
        </h2>
        <p>
          This website uses cookies to help you have a superior and more admissible 
          browsing experience on the website.{' '}
          <Link to="/privacy-policy">Read more</Link>
        </p>
        <div className="cookie-buttons">
          <button className="accept" onClick={handleAccept}>
            Accept
          </button>
          <button className="decline" onClick={handleDecline}>
            Decline
          </button>
        </div>
      </div>
    </>
  );
};

export default CookieConsent;