export const visaCategories = {
  study: {
    title: "Student Visas",
    paths: [
      {
        id: 'student-to-graduate',
        label: 'Student Visa → Graduate Visa',
        limit: 'No specific UKVI limit',
        period: 'Duration of course',
        type: 'institutional',
        conditions: [
          'Must maintain engagement and attendance as monitored by sponsoring institution',
          'Unauthorized absences may lead to visa sponsorship withdrawal',
          'Must complete course successfully',
          'Must apply for Graduate visa before Student visa expires'
        ]
      },
      {
        id: 'child-to-student',
        label: 'Child Student Visa → Student Visa',
        limit: 'No specific UKVI limit',
        period: 'Duration of course',
        type: 'institutional',
        conditions: [
          'Must maintain engagement and attendance as monitored by institution',
          'Must have guardian approval for absences',
          'Must meet academic progression requirements',
          'Must switch before turning 18'
        ]
      },
      {
        id: 'graduate-to-skilled',
        label: 'Graduate Visa → Skilled Worker Visa',
        limit: 'No specific limit',
        period: '2 years (3 years for doctoral graduates)',
        type: 'fixed',
        conditions: [
          'Must find eligible sponsored job before visa expires',
          'No restrictions on type of work, including self-employment',
          'Must meet salary requirements for Skilled Worker visa',
          'Previous absence limits don\'t apply'
        ]
      }
    ]
  },
  work: {
    title: "Work Visas",
    paths: [
      {
        id: 'skilled-to-ilr',
        label: 'Skilled Worker Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Absences must be work-related, family emergencies, or travel related to your job',
          'Must maintain continuous employment with licensed sponsor',
          'Must keep salary at required level throughout the period'
        ]
      },
      {
        id: 'health-care-to-ilr',
        label: 'Health and Care Worker Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must remain employed in eligible healthcare role',
          'Must maintain minimum salary requirements',
          'Must maintain registration with relevant professional body'
        ]
      },
      {
        id: 'global-talent-to-ilr',
        label: 'Global Talent Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the qualifying period',
          'Research-related absences can be exempted with proper documentation',
          'Can choose between 3 or 5-year route to ILR',
          'Must maintain endorsement from approved body'
        ]
      },
      {
        id: 'scale-up-to-ilr',
        label: 'Scale-up Worker Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must complete initial 6-month sponsored period',
          'Can work flexibly after initial sponsored period',
          'Must maintain minimum salary threshold'
        ]
      },
      {
        id: 'minister-to-ilr',
        label: 'Minister of Religion Visa (T2) → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must maintain religious worker status',
          'Must continue working for sponsoring organization',
          'Must meet English language requirements'
        ]
      },
      {
        id: 'sportsperson-to-ilr',
        label: 'Sportsperson Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must maintain endorsement from sporting body',
          'Must continue in eligible sporting role',
          'Must meet salary requirements'
        ]
      },
      {
        id: 'hpi-to-skilled',
        label: 'High Potential Individual Visa → Skilled Worker Visa',
        limit: 'No specific limit',
        period: '2 or 3 year fixed period',
        type: 'fixed',
        conditions: [
          'Must find eligible sponsored job before visa expires',
          'Must meet salary requirements for Skilled Worker visa',
          'Must switch before HPI visa expires',
          'No minimum time requirement in UK'
        ]
      },
      {
        id: 'innovator-to-ilr',
        label: 'Innovator Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 3-year qualifying period',
          'Must maintain active involvement in business',
          'Must maintain endorsement from approved body',
          'Business must remain viable and innovative'
        ]
      },
      {
        id: 'startup-to-innovator',
        label: 'Start-up Visa → Innovator Visa',
        limit: 'No specific limit',
        period: '2-year fixed period',
        type: 'fixed',
        conditions: [
          'Must switch to Innovator visa before Start-up visa expires',
          'Must maintain endorsement from approved body',
          'Must show business progress',
          'Cannot extend Start-up visa beyond 2 years'
        ]
      },
      {
        id: 'overseas-business-to-ilr',
        label: 'Representative of Overseas Business Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must remain employed by overseas company',
          'Must maintain senior status',
          'Company must maintain UK presence'
        ]
      },
      {
        id: 'investor-to-ilr',
        label: 'Investor Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during qualifying period',
          'Must maintain investment level',
          'Route length depends on investment amount (2, 3, or 5 years)',
          'Must maintain UK residential property'
        ]
      }
    ]
  },
  protection: {
    title: "Protection Routes",
    paths: [
      {
        id: 'refugee-to-ilr',
        label: 'Refugee Status → ILR',
        limit: 'No specific limit',
        period: '5-year protection period',
        type: 'protection',
        conditions: [
          'Should avoid returning to country of persecution unless necessary - returns may trigger review',
          'Must maintain UK residence',
          'Obtaining other nationality may affect status but is not strictly prohibited',
          'Must pass criminality checks'
        ]
      },
      {
        id: 'humanitarian-to-ilr',
        label: 'Humanitarian Protection → ILR',
        limit: 'No specific limit',
        period: '5-year protection period',
        type: 'protection',
        conditions: [
          'Should avoid returning to country of origin unless necessary - returns may trigger review',
          'Must maintain UK residence',
          'Obtaining other nationality may affect status but is not strictly prohibited',
          'Must pass criminality checks'
        ]
      },
      {
        id: 'ukraine-family-to-ilr',
        label: 'Ukraine Family Scheme → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during qualifying period',
          'Must maintain family relationship',
          'Must meet suitability requirements',
          'Must pass criminality checks'
        ]
      },
      {
        id: 'homes-ukraine-to-ilr',
        label: 'Homes for Ukraine → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during qualifying period',
          'Must maintain sponsorship arrangement initially',
          'Must meet suitability requirements',
          'Must pass criminality checks'
        ]
      },
      {
        id: 'ukraine-extension-to-ilr',
        label: 'Ukraine Extension Scheme → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during qualifying period',
          'Must have been in UK under Ukraine visa',
          'Must meet suitability requirements',
          'Must pass criminality checks'
        ]
      },
      {
        id: 'arap-to-ilr',
        label: 'Afghan Relocations and Assistance Policy → ILR',
        limit: 'No specific limit',
        period: 'Direct route to ILR',
        type: 'direct',
        conditions: [
          'Should avoid returning to Afghanistan - returns may trigger review',
          'Must maintain UK residence',
          'Must meet suitability requirements',
          'Must pass criminality checks'
        ]
      },
      {
        id: 'acrs-to-ilr',
        label: 'Afghan Citizens Resettlement Scheme → ILR',
        limit: 'No specific limit',
        period: 'Direct route to ILR',
        type: 'direct',
        conditions: [
          'Should avoid returning to Afghanistan - returns may trigger review',
          'Must maintain UK residence',
          'Must meet suitability requirements',
          'Must pass criminality checks'
        ]
      }
    ]
  },
  bno: {
    title: "Hong Kong BN(O) Routes",
    paths: [
      {
        id: 'bno-status-to-ilr',
        label: 'BN(O) Status Holder → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must maintain BN(O) status',
          'Must meet continuous residence requirement',
          'Must pass English language requirement'
        ]
      },
      {
        id: 'bno-household-to-ilr',
        label: 'BN(O) Household Member → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must maintain relationship with BN(O) status holder',
          'Must meet continuous residence requirement',
          'Must pass English language requirement'
        ]
      }
    ]
  },
  eu: {
    title: "EU Settlement Scheme",
    paths: [
      {
        id: 'pre-settled-to-settled',
        label: 'Pre-settled Status → Settled Status',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must apply before Pre-settled Status expires',
          'Must maintain continuous residence',
          'Must meet criminality requirements'
        ]
      },
      {
        id: 'settled-to-citizenship',
        label: 'Settled Status → British Citizenship',
        limit: '450 days in 5 years, 90 days in last 12 months',
        period: 'Fixed 5 years',
        type: 'fixed',
        maxDays: 450,
        conditions: [
          'Must hold ILR/Settled Status for 12 months before applying (unless spouse of British citizen)',
          'Cannot exceed total absence limits',
          'Must pass Life in the UK test',
          'Must meet English language requirement'
        ]
      }
    ]
  },
  family: {
    title: "Family and Partner Visas",
    paths: [
      {
        id: 'spouse-to-ilr',
        label: 'Family Visa (Spouse/Partner) → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must maintain genuine relationship',
          'Must meet financial requirements',
          'Must pass English language requirement'
        ]
      },
      {
        id: 'fiance-to-family',
        label: 'Fiancé(e) Visa → Family Visa',
        limit: 'No specific limit',
        period: '6-month fixed period',
        type: 'fixed',
        conditions: [
          'Must marry within 6 months',
          'Must switch to Family visa after marriage',
          'No work permitted on this visa',
          'Must meet English language requirement'
        ]
      },
      {
        id: 'parent-to-ilr',
        label: 'Parent of British Child Visa → ILR',
        limit: '180 days per 12-month period',
        period: 'Rolling 12 months',
        type: 'rolling',
        maxDays: 180,
        conditions: [
          'Cannot exceed 180 days in any 12-month period during the 5-year qualifying period',
          'Must maintain relationship with child',
          'Must meet financial requirements',
          'Must pass English language requirement'
        ]
      },
      {
        id: 'adult-dependent-to-ilr',
        label: 'Adult Dependent Relative → ILR',
        limit: 'No specific limit',
period: 'Direct route to ILR',
type: 'direct',
conditions: [
'Must maintain dependency relationship',
'Must receive adequate care in UK',
'Must meet financial requirements',
'Must not work during application process'
]
},
{
id: 'child-dependent-to-ilr',
label: 'Child Dependent Visa → ILR',
limit: '180 days per 12-month period',
period: 'Rolling 12 months',
type: 'rolling',
maxDays: 180,
conditions: [
'Cannot exceed 180 days in any 12-month period during qualifying period',
'Must maintain relationship with parent',
'Must be under 18',
'Must meet eligibility requirements'
]
},
{
id: 'bereaved-partner-to-ilr',
label: 'Bereaved Partner Route → ILR',
limit: 'No specific limit',
period: 'Direct route to ILR',
type: 'direct',
conditions: [
'Must have been residing in UK when partner died',
'Must have been in genuine relationship',
'Must meet suitability requirements',
'No specific residence requirement'
]
}
]
},
settlement: {
title: "Settlement and Citizenship",
paths: [
{
id: 'ilr-to-citizenship',
label: 'ILR → British Citizenship',
limit: '450 days in 5 years, 90 days in last 12 months',
period: 'Fixed 5 years',
type: 'fixed',
maxDays: 450,
daysLastYear: 90,
conditions: [
'Must hold ILR for 12 months before applying (unless spouse of British citizen)',
'Must pass Life in the UK test',
'Must meet English language requirement',
'Must meet good character requirements'
]
}
]
},
nationality: {
title: "British Nationality Routes",
paths: [
{
id: 'protected-person-to-citizenship',
label: 'British Protected Person → British Citizenship',
limit: 'Case-by-case basis',
period: '5 years',
type: 'special',
conditions: [
'Must meet residence requirements',
'Must pass Life in the UK test',
'Must meet English language requirement',
'Must meet good character requirements'
]
},
{
id: 'bot-to-citizenship',
label: 'British Overseas Territories Citizen → British Citizenship',
limit: 'Case-by-case basis',
period: '5 years',
type: 'special',
conditions: [
'Must meet residence requirements',
'Must pass Life in the UK test',
'Must meet English language requirement',
'Must meet good character requirements'
]
},
{
id: 'boc-to-citizenship',
label: 'British Overseas Citizen → British Citizenship',
limit: 'Case-by-case basis',
period: '5 years',
type: 'special',
conditions: [
'Must meet residence requirements',
'Must pass Life in the UK test',
'Must meet English language requirement',
'Must meet good character requirements'
]
},
{
id: 'british-subject-to-citizenship',
label: 'British Subject → British Citizenship',
limit: 'Case-by-case basis',
period: '5 years',
type: 'special',
conditions: [
'Must meet residence requirements',
'Must pass Life in the UK test',
'Must meet English language requirement',
'Must meet good character requirements'
]
},
{
id: 'bno-to-citizenship',
label: 'British National (Overseas) → British Citizenship',
limit: 'Case-by-case basis',
period: '5 years',
type: 'special',
conditions: [
'Must meet residence requirements',
'Must pass Life in the UK test',
'Must meet English language requirement',
'Must meet good character requirements'
]
}
]
},
gbm: {
title: "Global Business Mobility Visas",
paths: [
{
id: 'senior-specialist-to-skilled',
label: 'Senior/Specialist Worker → Skilled Worker Visa',
limit: 'No specific limit',
period: 'Up to 5 years in any 6-year period',
type: 'fixed',
conditions: [
'Must find eligible sponsored job',
'Must meet Skilled Worker salary requirements',
'Must switch before visa expires',
'Previous absences don\'t affect Skilled Worker route'
]
},
{
id: 'graduate-trainee-to-skilled',
label: 'Graduate Trainee → Skilled Worker Visa',
limit: 'No specific limit',
period: '12-month fixed period',
type: 'fixed',
conditions: [
'Must complete graduate training program',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
},
{
id: 'uk-expansion-to-skilled',
label: 'UK Expansion Worker → Skilled Worker Visa',
limit: 'No specific limit',
period: '2-year fixed period',
type: 'fixed',
conditions: [
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires',
'Company must establish UK presence'
]
},
{
id: 'service-supplier-to-skilled',
label: 'Service Supplier → Skilled Worker Visa',
limit: 'No specific limit',
period: '6 or 12 months depending on agreement',
type: 'fixed',
conditions: [
'Must complete service contract',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
},
{
id: 'secondment-to-skilled',
label: 'Secondment Worker → Skilled Worker Visa',
limit: 'No specific limit',
period: '12-month fixed period',
type: 'fixed',
conditions: [
'Must complete secondment',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
}
]
},
temporary: {
title: "Temporary Worker Visas",
paths: [
{
id: 'charity-to-skilled',
label: 'Charity Worker (T5) → Skilled Worker Visa',
limit: 'No specific limit',
period: '12-month fixed period',
type: 'fixed',
conditions: [
'Must complete charitable work',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
},
{
id: 'creative-to-skilled',
label: 'Creative Worker (T5) → Skilled Worker Visa',
limit: 'No specific limit',
period: '12-month fixed period',
type: 'fixed',
conditions: [
'Must complete creative work engagement',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
},
{
id: 'religious-to-skilled',
label: 'Religious Worker (T5) → Skilled Worker Visa',
limit: 'No specific limit',
period: '24-month fixed period',
type: 'fixed',
conditions: [
'Must complete religious work',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
},
{
id: 'govt-authorized-exchange-to-skilled',
label: 'Government Authorized Exchange (T5) → Skilled Worker Visa',
limit: 'No specific limit',
period: '12 or 24 months depending on scheme',
type: 'fixed',
conditions: [
'Must complete exchange program',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
},
{
id: 'international-agreement-to-ilr',
label: 'International Agreement (T5) → ILR',
limit: '180 days per 12-month period',
period: 'Rolling 12 months',
type: 'rolling',
maxDays: 180,
conditions: [
'Cannot exceed 180 days in any 12-month period during qualifying period',
'Must maintain work under international agreement',
'Must meet specific agreement requirements',
'Route length depends on agreement type'
]
},
{
id: 'seasonal-worker-to-skilled',
label: 'Seasonal Worker → Skilled Worker Visa',
limit: 'No specific limit',
period: '6-month fixed period',
type: 'fixed',
conditions: [
'Must complete seasonal work',
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires'
]
},
{
id: 'youth-mobility-to-skilled',
label: 'Youth Mobility Scheme → Skilled Worker Visa',
limit: 'No specific limit',
period: '2-year fixed period',
type: 'fixed',
conditions: [
'Must find eligible sponsored job',
'Must meet Skilled Worker requirements',
'Must switch before visa expires',
'One-time opportunity only'
]
}
]
}
};
// Helper functions
export const getVisaPath = (pathId) => {
for (const category of Object.values(visaCategories)) {
const path = category.paths.find(p => p.id === pathId);
if (path) return path;
}
return null;
};
export const getAbsenceType = (pathId) => {
const path = getVisaPath(pathId);
return path ? path.type : 'fixed';
};
export const getMaxDays = (pathId) => {
const path = getVisaPath(pathId);
return path?.maxDays || null;
};
export const hasAbsenceLimit = (pathId) => {
const path = getVisaPath(pathId);
return path?.limit !== 'No specific limit' && path?.limit !== 'No specific UKVI limit';
};
export const getCategories = () => {
return Object.keys(visaCategories).map(key => ({
id: key,
title: visaCategories[key].title
}));
};
export const getCategoryPaths = (categoryId) => {
return visaCategories[categoryId]?.paths || [];
};
export default visaCategories;