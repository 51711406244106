import React from 'react';

const PrivacyPolicy = () => {
    return (
        <div className="App">
            <div className="policy-container">
                <h1>Privacy Policy</h1>
                <div className="policy-content">
                    <section>
                        <h2>1. Data Storage</h2>
                        <p>UK Travel Tracker stores your data using Firebase, a secure cloud storage service provided by Google. Your travel records and preferences are stored in our Firebase database with industry-standard security measures in place.</p>
                    </section>

                    <section>
                        <h2>2. Data Collection</h2>
                        <p>We collect and store the following information:</p>
                        <ul>
                            <li>Your travel records</li>
                            <li>Interface preferences</li>
                            <li>Calculation settings</li>
                        </ul>
                        <p>This data is stored securely in our Firebase database and is only accessible through your authenticated account.</p>
                    </section>

                    <section>
                        <h2>3. Data Security</h2>
                        <p>We implement several measures to ensure your data's security:</p>
                        <ul>
                            <li>All data is encrypted in transit and at rest</li>
                            <li>Access to the database is strictly controlled through Firebase security rules</li>
                            <li>Regular security audits and updates are performed</li>
                        </ul>
                    </section>

                    <section>
                        <h2>4. Data Control</h2>
                        <p>You maintain control over your data and can:</p>
                        <ul>
                            <li>Export your records at any time</li>
                            <li>Delete your account and associated data</li>
                            <li>Access and modify your records through the application</li>
                            <li>Request a copy of all your stored data</li>
                        </ul>
                    </section>

                    <section>
                        <h2>5. Data Usage</h2>
                        <p>Your data is used solely for providing the UK Travel Tracker service. We do not sell or share your personal information with third parties.</p>
                    </section>

                    <section>
                        <h2>6. Firebase Analytics</h2>
                        <p>We use Firebase Analytics to collect anonymous usage statistics that help us improve the application. This includes:</p>
                        <ul>
                            <li>App usage patterns</li>
                            <li>Feature popularity</li>
                            <li>Performance metrics</li>
                        </ul>
                        <p>No personally identifiable information is included in these analytics.</p>
                    </section>

                    <section>
                        <h2>7. Updates to This Policy</h2>
                        <p>We may update this privacy policy from time to time. Any changes will be posted on this page and, where appropriate, you will be notified.</p>
                    </section>
                </div>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
