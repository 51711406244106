// src/components/WarningMessages.jsx
import React from 'react';

function WarningMessages({ warnings }) {
  if (!warnings?.length) return null;

  return (
    <div className="warnings-container">
      {warnings.map((warning, index) => (
        <div 
          key={index}
          className={`warning-message ${warning.type}`}
        >
          {warning.message}
        </div>
      ))}
    </div>
  );
}

export default WarningMessages;